import logo from "./logo.svg";
import "./App.css";
import Editor from "./pages/Editor/Editor";
import {
  LanguageProvider,
  loadLanguageTranslations,
} from "./providers/LanguageProvider";
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/font-awesome.css";
import "../src/assets/css/tor.css";
import "../src/assets/css/eskritor.css";
import "../src/assets/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./Auth/AuthContext";
import { ModalProvider } from "./components/common/Modal/ModalContext";
import BootstrapModal from "./components/common/Modal/BootstrapModal";
import { DirectionProvider } from "./components/common/DirectionContext";
import Gleap from "gleap";

function App() {
  loadLanguageTranslations(localStorage.getItem("dashboard-language") || 'en');
  Gleap.initialize("Tv138XkTKxwmFvms7fyRa5H6spmOMvvk");
  Gleap.setLanguage(localStorage.getItem('dashboard-language') || "en")
  //navigate to app.eskritor.com
  window.location.href = "https://app.eskritor.com";
  
  return (
    <DirectionProvider>
    <LanguageProvider>
      <ModalProvider>
      <BrowserRouter>
      <AuthProvider>
        <div className="App">
        <BootstrapModal />
          <ToastContainer />
          <Routes>
            <Route path="/:sk?/:pk?" element={<Editor />} />
          </Routes>
        </div>
        </AuthProvider>
      </BrowserRouter>
      </ModalProvider>
    </LanguageProvider>
    </DirectionProvider>
  );
}

export default App;
